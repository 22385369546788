import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import Plx from "react-plx";
import heroGB from "@media/backgrounds/hero-bg.svg";
import Navigation from "../components/navigation";

const Header = ({
  title,
  text,
  image,
  blue,
  buttons,
  secondaryNavigation,
  useBGImage
}) => (
  <section
    className="center white"
    css={css`
      background: radial-gradient(circle, #6b6b6b 0%, #505050 0%, #000 100%);
    `}
  >
    <div
      css={css`
        padding-top: 12rem;
        background-image: url(${heroGB});
        background-position: bottom center;
        background-size: cover;
      `}
      className="px2 md-px3 relative"
    >
      {secondaryNavigation && <Navigation navigation={secondaryNavigation} />}
      <Plx
        parallaxData={[
          {
            start: "self",
            end: "100vh",
            properties: [
              {
                startValue: 0,
                endValue: -20,
                property: "translateY"
              }
            ]
          }
        ]}
      >
        {title}
        <p className="large-p-tag max-width-3 mx-auto mt1 mb3" css={css`
          font-size: 1.4em !important;
        `}>{text}</p>
      </Plx>
      <Plx
        parallaxData={[
          {
            start: "self",
            end: "100vh",
            properties: [
              {
                startValue: 0,
                endValue: 10,
                property: "translateY"
              }
            ]
          }
        ]}
      >
        <div
          className="relative mx-auto col-12 mt4 mb4 pb4"
          css={css`
            max-width: 48rem;
            z-index: -1;
          `}
        >
          {image}
        </div>
      </Plx>
    </div>
  </section>
);

Header.defaultProps = {
  blue: false,
  buttons: [],
  secondaryNavigation: null,
  useBGImage: false
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.node.isRequired,
  blue: PropTypes.bool,
  buttons: PropTypes.array,
  secondaryNavigation: PropTypes.any
};

export default Header;
